// import { useState } from 'react';
// import { showToast } from '../../APIFunctions/Helpers/toastHelpers';
// import { add_contact } from '../../APIFunctions/App/Contact';
// import { isResponseError } from '../../APIFunctions/Helpers/commonHelpers';
// import CircularLoader from '../Common/CircularLoader';

import { Link } from 'react-router-dom';

export interface Contact {
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
    business_type: string;
    business_name: string;
    number_of_locations: string;
}
// const initial_contact: Contact = {
//     first_name: '',
//     last_name: '',
//     phone_number: '',
//     email: '',
//     business_type: '',
//     business_name: '',
//     number_of_locations: '',
// };

export default function ContactUs() {
    // const [new_contact, setNewContact] = useState<Contact>(initial_contact);
    // const [loading, setLoading] = useState<boolean>(false);

    // const handleInputChange = (event) => {
    //     const { name, value } = event.target;
    //     setNewContact({ ...new_contact, [name]: value });
    // };

    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     saveContact(new_contact);
    // };

    // const isValidContactUs = (obj: Contact) => {
    //     const requiredFields = [
    //         'first_name',
    //         'last_name',
    //         'phone_number',
    //         'email',
    //         'business_name',
    //         'business_type',
    //         'number_of_locations',
    //     ];
    //     return requiredFields.every((field) => obj[field].length > 0);
    // };
    // const saveContact = async (e) => {
    //     if (isValidContactUs(new_contact)) {
    //         setLoading(true);
    //         var newContact = await add_contact(new_contact);
    //         if (!isResponseError(newContact)) {
    //             showToast('success', 'Thank you for contacting us, One of our team will be in touch soon.');
    //             setNewContact(initial_contact);
    //         }
    //         setLoading(false);
    //     }
    // };
    return (
        <>
            <section className="hero hero-inner-page contact-hero">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <p className="intro">Contact us</p>
                            <br />
                            <br />
                            <div className="flex-grow-1 pb-4 d-flex flex-column justify-space-between">
                                <div className="flex-grow-1">
                                    <p className="date"></p>
                                    <h4>Do you want to learn more about URBX ?</h4>
                                    <br />
                                    <p>
                                        Send an email at <a href="mailto:sales@urbx.com">sales@urbx.com</a>, and one of
                                        our representatives will contact you.
                                    </p>
                                    <a className="btn btn-cta mt-4" href="mailto:sales@urbx.com">
                                        Send an email
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <video
                                width="100%"
                                height="auto"
                                autoPlay
                                loop
                                muted
                                style={{ borderRadius: '20px;' }}
                                className="img-fluid"
                                playsInline
                            >
                                <source src="assets/img/gridwhite.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="cta"
                style={{
                    marginBottom: '60px',
                }}
            >
                <div className="container">
                    <div className="bluebg">
                        <h3>
                            Get ready to bring <span>Urbx</span>
                            <br />
                            to your Warehouse
                        </h3>
                        <div className="btns">
                            <Link to="mailto:sales@urbx.com" className="transparent">
                                Contact Us
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="contact graybg">
                <div className="container">
                    <div className="bg">
                        <div className="row">
                            <p className="intro">Do you want to learn more about URBX ?</p>
                            <h2>
                                Just send an email<br />
                            </h2>
                            <h5> at <a href="mailto:sales@urbx.com">sales@urbx.com</a> and one of our representatives will contact you.
                            </h5>
                        </div>
                        <div className="row r-px-3">
                            <div className="col-lg-6">
                                <div className="row r-pe-2">
                                    <div className="col-lg-3 col-4 label">
                                        <p>
                                            First Name <span>*</span>
                                        </p>
                                    </div>
                                    <div className="col-lg-9 col-8">
                                        <input
                                            type="text"
                                            className="h-100"
                                            disabled={loading}
                                            name="first_name"
                                            value={new_contact.first_name}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row r-ps-2">
                                    <div className="col-lg-3 col-4 label">
                                        <p>
                                            Last Name <span>*</span>
                                        </p>
                                    </div>
                                    <div className="col-lg-9 col-8">
                                        <input
                                            type="text"
                                            className="h-100"
                                            disabled={loading}
                                            name="last_name"
                                            value={new_contact.last_name}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row r-pe-2">
                                    <div className="col-lg-3 col-4 label">
                                        <p>
                                            Phone number <span>*</span>
                                        </p>
                                    </div>
                                    <div className="col-lg-9 col-8">
                                        <input
                                            type="text"
                                            className="h-100"
                                            disabled={loading}
                                            name="phone_number"
                                            value={new_contact.phone_number}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row r-ps-2">
                                    <div className="col-lg-3 col-4 label">
                                        <p>
                                            Email Address <span>*</span>
                                        </p>
                                    </div>
                                    <div className="col-lg-9 col-8">
                                        <input
                                            type="text"
                                            className="h-100"
                                            disabled={loading}
                                            name="email"
                                            value={new_contact.email}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row r-pe-2">
                                    <div className="col-lg-3 col-4 label">
                                        <p>
                                            Business type <span>*</span>
                                        </p>
                                    </div>
                                    <div className="col-lg-9 col-8">
                                        <input
                                            type="text"
                                            className="h-100"
                                            disabled={loading}
                                            name="business_type"
                                            value={new_contact.business_type}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row r-ps-2">
                                    <div className="col-lg-3 col-4 label">
                                        <p>
                                            Business Name <span>*</span>
                                        </p>
                                    </div>
                                    <div className="col-lg-9 col-8">
                                        <input
                                            type="text"
                                            className="h-100"
                                            disabled={loading}
                                            name="business_name"
                                            value={new_contact.business_name}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12" style={{ marginTop: '10px' }}>
                                <div className="row d-flex justify-content-center align-items-stretch">
                                    <div className="col-lg-3 col-4 label">
                                        <p>
                                            Number of locations <span>*</span>
                                        </p>
                                    </div>
                                    <div className="col-lg-9 col-8">
                                        <input
                                            type="number"
                                            disabled={loading}
                                            min="0"
                                            max="100"
                                            name="number_of_locations"
                                            value={new_contact.number_of_locations}
                                            onChange={handleInputChange}
                                            className="h-100"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row copyright-from">
                                <div className="col-lg-8"></div>
                                <div className="col-lg-4 d-flex justify-content-end align-items-center">
                                    <div className="btns">
                                        {loading ? (
                                            <CircularLoader color={'#00a5e2'} size={40} />
                                        ) : (
                                            <button
                                                disabled={loading || !isValidContactUs(new_contact)}
                                                className="btn subscribe"
                                                onClick={handleSubmit}
                                            >
                                                {' '}
                                                Submit{' '}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </section> */}
        </>
    );
}
