import { Link } from 'react-router-dom';
import Subscribe from '../Subscribe/Subscribe';

export default function AppFooter() {
    return (
        <footer className="graybg">
            <div className="container">
                <div className="row">
                    <div className="bg">
                        <div className="footer-botton">
                            <div className="top-footer main-order">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-2 footer-logo">
                                            <img src="assets/img/footer-logo.svg" alt="" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-2 col-6">
                                            <h4>Urbx</h4>
                                            <ul>
                                                <li>
                                                    <Link to={'/enterprise'}>Enterprise</Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-2 col-6">
                                            <h4>Insights</h4>
                                            <ul>
                                                <li>
                                                    <Link to={'/news'}>News</Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-2 col-6">
                                            <h4>About Us</h4>
                                            <ul>
                                                <li>
                                                    <Link to={'/contact-us'}>Contact us</Link>
                                                </li>
                                                <li>
                                                    <Link to={'/about-us'}>About</Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-4 subscribe-footer">
                                            <h4>Newsletter</h4>
                                            <Subscribe />
                                            <ul className="social mobile">
                                                <button>
                                                    <li>
                                                        <img
                                                            src="assets/img/twitter.svg"
                                                            alt=""
                                                            className="img-fluid"
                                                        />
                                                    </li>{' '}
                                                </button>
                                                <button>
                                                    <li>
                                                        <img
                                                            src="assets/img/instagram.svg"
                                                            alt=""
                                                            className="img-fluid"
                                                        />
                                                    </li>
                                                </button>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="footer-bottom second-order">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <ul className="social dekstop">
                                            <Link
                                                style={{
                                                    cursor: 'default',
                                                    border: 'none',
                                                    backgroundColor: 'transparent',
                                                }}
                                                to="http://twitter.com/urbx_inc"
                                            >
                                                <li>
                                                    <img
                                                        src="assets/img/twitter.svg"
                                                        alt=""
                                                        className="img-fluid" />
                                                </li>{' '}
                                            </Link>
                                            <Link
                                                style={{
                                                    cursor: 'default',
                                                    border: 'none',
                                                    backgroundColor: 'transparent',
                                                }}
                                                to="http://instagram.com/urbx_inc"

                                            >
                                                <li>
                                                    <img
                                                        src="assets/img/instagram.svg"
                                                        alt=""
                                                        className="img-fluid" />
                                                </li>
                                            </Link>
                                        </ul>
                                    </div>
                                    <div className="col-lg-6">
                                        <ul className="copyright">
                                            <li>
                                                <button
                                                    style={{
                                                        cursor: 'default',
                                                        border: 'none',
                                                        backgroundColor: 'transparent',
                                                    }}
                                                >
                                                    TERMS OF USE
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    style={{
                                                        cursor: 'default',
                                                        border: 'none',
                                                        backgroundColor: 'transparent',
                                                    }}
                                                >
                                                    DATA PROTECTION POLICY
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    style={{
                                                        cursor: 'default',
                                                        border: 'none',
                                                        backgroundColor: 'transparent',
                                                    }}
                                                >
                                                    @URBX 2023
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
