import React from 'react';
import { Link } from 'react-router-dom';

export default function EdgeServices() {
    return (
        <>
            <section className="hero hero-inner-page">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-4">
                            <p className="intro">Software SERVICES</p>
                            <h2>
                                Urbx <br />
                                <span>Edge Services</span>
                            </h2>
                            <p>Facilitate everything from the customer.</p>
                            <div className="btns">
                                <Link className="black" to="https://dashboard.urbx.com">
                                    Sign In
                                </Link>
                                <Link to="/contact-us" className="white">
                                    Contact Us
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <video
                                autoPlay
                                loop
                                muted
                                playsInline
                                style={{ borderRadius: '20px;' }}
                                className="img-fluid bigvideo shadow-lg"
                            >
                                <source src="assets/img/draft.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </section>

            <section className="visability img-gap graybg p100">
                <div className="container">
                    <div className="row d-flex align-items-center custom-bm50">
                        <div className="col-lg-5">
                            <h4>
                                System <span>Visability</span>
                            </h4>
                        </div>
                        <div className="col-lg-7">
                            <p>
                                Experience unparalleled system visibility with Urbx's innovative warehouse solutions,
                                designed to provide complete transparency and control over your operations. Our advanced
                                technology offers real-time insights into inventory levels, order statuses, and
                                warehouse performance metrics, empowering you to make informed decisions and optimize
                                your processes.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <video width="100%" style={{ borderRadius: '20px' }} loop autoPlay muted playsInline>
                                <source src="assets/img/sstatus.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </section>

            <section className="activity img-gap graybg p100">
                <div className="container">
                    <div className="row mobile-gap-50">
                        <div className="col-lg-4">
                            <h4>
                                Pick and <span>Pack</span>
                            </h4>
                            <p style={{ paddingRight: '50px' }}>
                                Incorporating novel items into your stock is a breeze. Simply scan your merchandise, and
                                our advanced automatons will deliver appropriate containers, ensuring precise item
                                placement.
                            </p>
                        </div>
                        <div className="col-lg-8">
                            <video width="100%" style={{ borderRadius: '20px' }} loop autoPlay muted playsInline>
                                <source src="assets/img/pick.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </section>

            <section className="activity img-gap graybg p100">
                <div className="container">
                    <div className="row responsive-reverse mobile-gap-50">
                        <div className="col-lg-8">
                            <img src="assets/img/activity.png" alt="" className="img-fluid" />
                        </div>
                        <div className="col-lg-4">
                            <h4 style={{ paddingLeft: '50px;' }}>
                                Fulfillment <span>Manager</span>
                            </h4>
                            <p style={{ paddingLeft: '50px;' }}>
                                Discover the power of Urbx's Fulfillment Manager, a robust and user-friendly solution
                                designed to revolutionize your order management and execution processes. With a focus on
                                efficiency and accuracy, our cutting-edge software enables you to effortlessly oversee
                                and optimize all aspects of your fulfillment operations.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pap img-gap graybg p100">
                <div className="container">
                    <div className="row custom-bm50">
                        <div className="col-lg-4">
                            <h4>
                                AI <span>Controller</span>
                            </h4>
                        </div>
                        <div className="col-lg-8">
                            <p>
                                The Urbx AI Controller excels at order processing, integrating smoothly with the
                                warehouse management system. In synergy with our software, the Controller guides Robots
                                to accurately identify and gather totes. Leveraging our emerging advanced AI platform,
                                the Controller is designed to learn and dynamically fine-tune Robot paths and placements
                                within the Grid in real-time.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8">
                            <img src="assets/img/pap.png" alt="" className="img-fluid" />
                        </div>
                        <div className="col-4">
                            <video width="100%" style={{ borderRadius: '20px' }} loop autoPlay muted playsInline>
                                <source src="assets/img/pap.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faq graybg p100">
                <div className="container">
                    <div className="bg">
                        <div className="row">
                            <div className="col-lg-4 hidden-below-tablet">
                                <p className="intro">faq</p>
                                <div
                                    className="m-3 ms-0 mt-4 overflow-hidden"
                                    style={{
                                        borderRadius: '20px',
                                    }}
                                >
                                    <img
                                        src="assets/img/faq.png"
                                        alt="urbx faq"
                                        className="img-fluid"
                                        style={{
                                            maxWidth: '90%;',
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <h3>
                                    Frequently Asked <span>Questions</span>
                                </h3>
                                <p>We’re here for you. Browse our FAQ below</p>
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button
                                                className="accordion-button"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseOne"
                                                aria-expanded="true"
                                                aria-controls="collapseOne"
                                            >
                                                How many totes can each Urbx station process hourly?
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseOne"
                                            className="accordion-collapse collapse show"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                <strong>
                                                    Our design aims to handle between 300 - 500 totes every hour.
                                                </strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseTwo"
                                                aria-expanded="false"
                                                aria-controls="collapseTwo"
                                            >
                                                How tall can the Urbx storage racks be set up?
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseTwo"
                                            className="accordion-collapse collapse"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                <strong>
                                                    Our design aims for rack assemblies up to a height of 125 feet.
                                                </strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapse3"
                                                aria-expanded="false"
                                                aria-controls="collapse3"
                                            >
                                                How long should I anticipate the Urbx system setup to take?
                                            </button>
                                        </h2>
                                        <div
                                            id="collapse3"
                                            className="accordion-collapse collapse"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                <strong>
                                                    Depending on the size and specific requirements, most Urbx
                                                    installations are typically completed within a 6 to 8 month
                                                    timeframe.
                                                </strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapse4"
                                                aria-expanded="false"
                                                aria-controls="collapse4"
                                            >
                                                What's the load-bearing capacity of the Urbx TowerBot?
                                            </button>
                                        </h2>
                                        <div
                                            id="collapse4"
                                            className="accordion-collapse collapse"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                <strong>
                                                    The TowerBot is designed with a target carrying capacity of 50 - 100
                                                    lbs, depending on specific conditions and use cases.
                                                </strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapse5"
                                                aria-expanded="false"
                                                aria-controls="collapse5"
                                            >
                                                What's the movement speed of the Urbx TowerBot?
                                            </button>
                                        </h2>
                                        <div
                                            id="collapse5"
                                            className="accordion-collapse collapse"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                <strong>
                                                    The TowerBot is designed to achieve movement speeds of 2 - 4 m/s
                                                    horizontally and 4 - 8 m/s vertically.
                                                </strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapse6"
                                                aria-expanded="false"
                                                aria-controls="collapse6"
                                            >
                                                What kind of battery system powers the Urbx TowerBots?
                                            </button>
                                        </h2>
                                        <div
                                            id="collapse6"
                                            className="accordion-collapse collapse"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                <strong>
                                                    Our TowerBots utilize the advanced graphene-based super-capacitors
                                                    from Skeleton Technologies.
                                                </strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapse7"
                                                aria-expanded="false"
                                                aria-controls="collapse7"
                                            >
                                                What's the operational duration of a TowerBot on a single charge?
                                            </button>
                                        </h2>
                                        <div
                                            id="collapse7"
                                            className="accordion-collapse collapse"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                <strong>
                                                    Thanks to our innovative opportunity charging system, Urbx TowerBots
                                                    can function continuously, around the clock.
                                                </strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p>Don’t have an answer?</p>
                                <Link to="/contact-us">Contact Us</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="cta graybg">
                <div className="container">
                    <div className="bluebg">
                        <h3>Get ready bring Urbx to your Warehouse</h3>
                        <div className="btns">
                            <Link to="/contact-us" className="transparent">
                                Contact Us
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
