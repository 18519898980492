import React from 'react';
import { Link } from 'react-router-dom';

export default function CarbonNews() {
    return (
        <>
            <section className="hero hero-inner-page news-hero">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <h2>
                                Insight for <br />
                                fulfillment <span>innovation.</span>
                            </h2>
                        </div>

                        <div className="col-lg-6 desktop-news">
                            <div className="read-more-blogs">
                                <div className="row d-flex justify-content-center align-items-center">
                                    <div className="col-lg-11 col-9">
                                        <h5>More News</h5>
                                    </div>
                                    <div className="col-lg-1 col-3">
                                        <img src="assets/img/arrow.svg" alt="" className="" />
                                    </div>
                                </div>
                            </div>
                            <section className="insight">
                                <div className="blog-list">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="inner">
                                                <div
                                                    className="thumbnail"
                                                    style={{ background: 'url(assets/img/blog1.png)' }}
                                                ></div>
                                                <div className="flex-grow-1 pb-4 d-flex flex-column justify-space-between">
                                                    <div className="flex-grow-1">
                                                        <h4>Amicable Resolution Between Urbx and Attabotics</h4>
                                                    </div>

                                                    <Link to="/attabotics-news">Read More</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="inner">
                                                <div
                                                    className="thumbnail"
                                                    style={{ background: 'url(assets/img/blog2.png)' }}
                                                ></div>
                                                <div className="flex-grow-1 pb-4 d-flex flex-column justify-space-between">
                                                    <div className="flex-grow-1">
                                                        <h4>
                                                            Cutting Costs and Carbon: The Benefits of MFCs for E-Com
                                                        </h4>
                                                    </div>

                                                    <Link to="/carbon-news">Read More</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>

            <section className="only-image-two"></section>

            <div className="news-hero container hero mobile-news">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="read-more-blogs">
                            <div className="row d-flex justify-content-center align-items-center">
                                <div className="col-lg-11 col-9">
                                    <h5>More News</h5>
                                </div>
                                <div className="col-lg-1 col-3">
                                    <img src="assets/img/arrow.svg" alt="" className="" />
                                </div>
                            </div>
                        </div>
                        <section className="insight">
                            <div className="container">
                                <div className="row">
                                    <div className="blog-list">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="inner">
                                                    <div
                                                        className="thumbnail"
                                                        style={{ background: 'url(assets/img/blog1.png)' }}
                                                    ></div>

                                                    <h4>4 massive barries to scale solved by local fulfillment</h4>

                                                    <button>Read More</button>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="inner">
                                                    <div
                                                        className="thumbnail"
                                                        style={{ background: 'url(assets/img/blog2.png)' }}
                                                    ></div>
                                                    <h4>4 massive barries to scale solved by local fulfillment</h4>

                                                    <button>Read More</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>

            <section className="news-single">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <p className="intro">Costs and Carbon</p>
                        </div>
                        <div className="col-lg-9">
                            <h3>Cutting Costs and Carbon: The Benefits of Micro-Fulfillment Centers for E-Commerce</h3>
                            <p>
                                The increasing costs of commercial real estate can be a major challenge for businesses,
                                especially in the e-commerce sector where demand for industrial space has soared in
                                recent years. According to a report by real estate firm CBRE Group Inc., the cost to
                                rent an industrial property has increased by an average of 25% compared to the rates
                                that were being paid at the end of five-year contracts that expired in 2021. One
                                solution that could help offset these rising costs is the use of vertical fulfillment
                                automation, such as micro-fulfillment centers (MFCs). These automated warehouses and
                                pickup areas offer a more efficient and cost-effective way to store and distribute
                                products, particularly in urban environments where space is limited.
                            </p>
                            <br />
                            <p>
                                One major factor driving the demand for industrial space is the rapid growth of the
                                e-commerce industry, which saw a significant increase in its share of all retail sales
                                following the outbreak of COVID-19. In the U.S. alone, e-commerce added $105 billion to
                                the economy in 2020, and global e-commerce reached $26.7 trillion in 2021. MFCs are
                                expected to save consumers 20 to 40% per order compared to ordering manually, and their
                                convenience, speed, and price make them an attractive option for retailers and consumers
                                alike. By integrating these automation technologies into their operations, businesses
                                can reduce their reliance on costly warehouse space and improve their efficiency and
                                profitability.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="insight">
                <div className="container">
                    <div className="row">
                        <div className="title">
                            <h3>
                                Insight for fulfillment <br />
                                <span>innovation</span>
                            </h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="blog-list">
                            <div className="row sm-tablet-gap">
                                <div className="col-10 col-sm-8 mx-auto col-md-4">
                                    <div className="inner">
                                        <div
                                            className="thumbnail"
                                            style={{ background: 'url(assets/img/blog1.png)' }}
                                        ></div>
                                        <div className="flex-grow-1 pb-4 d-flex flex-column justify-space-between">
                                            <div className="flex-grow-1">
                                                <p className="date">Blog . 5 min</p>
                                                <h4>Amicable Resolution Between Urbx and Attabotics</h4>
                                                <p>
                                                    Urbx is pleased to announce that Attabotics and Urbx have amicably
                                                    resolved.
                                                </p>
                                            </div>
                                            <Link to="/attabotics-news">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-10 col-sm-8 mx-auto col-md-4">
                                    <div className="inner">
                                        <div
                                            className="thumbnail"
                                            style={{ background: 'url(assets/img/blog2.png)' }}
                                        ></div>
                                        <div className="flex-grow-1 pb-4 d-flex flex-column justify-space-between">
                                            <div className="flex-grow-1">
                                                <p className="date">Blog . 5 min</p>
                                                <h4>Cutting Costs and Carbon: The Benefits of MFCs for E-Com</h4>
                                                <p>
                                                    The URBX Double Deep TowerBot is a cutting-edge solution for
                                                    warehouses and distribution...
                                                </p>
                                            </div>
                                            <Link to="/carbon-news">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-10 col-sm-8 mx-auto col-md-4">
                                    <div className="inner">
                                        <div
                                            className="thumbnail"
                                            style={{ background: 'url(assets/img/blog3.png)' }}
                                        ></div>
                                        <div className="flex-grow-1 pb-4 d-flex flex-column justify-space-between">
                                            <div className="flex-grow-1">
                                                <p className="date">Blog . 5 min</p>
                                                <h4>Transforming the Grocery Industry with Drive Thru</h4>
                                                <p>The grocery industry is on the brink of a major transformation...</p>
                                            </div>
                                            <Link to="/news">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="cta graybg c-padding">
                <div className="container">
                    <div className="row">
                        <div className="bluebg">
                            <h3>
                                Get ready bring <span>Urbx</span>
                                <br />
                                to your Warehouse
                            </h3>
                            <div className="btns">
                                <Link to="/contact-us" className="transparent">
                                    Contact Us
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
