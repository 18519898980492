import { Link } from 'react-router-dom';

export default function Enterprise() {
    return (
        <>
            <section className="hero hero-inner-page">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-4">
                            <p className="intro">Enterprise Fufillment</p>
                            <h2>
                                Urbx <br />
                                <span>Enterprise</span>
                            </h2>
                            <p>Premium Fulfillment Solutions.</p>
                            <div className="btns">
                                <Link to="/contact-us" className="white">
                                    Contact Us
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <video
                                autoPlay
                                loop
                                muted
                                playsInline
                                style={{ borderRadius: '20px;' }}
                                className="img-fluid bigvideo shadow-lg"
                            >
                                <source src="assets/img/draft.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </section>

            <section className="who">
                <div className="container">
                    <div className="row d-flex align-items-center bg">
                        <div className="col-sm-4 order who-bg ps-0">
                            <img src="/assets/img/illustration.png" alt="" />
                        </div>
                        <div className="col-sm-8 d-flex justify-content-center who-content">
                            <div className="max-container">
                                <h4>
                                    <span>Urbx</span> Key Features
                                </h4>
                                <p>
                                    Our TowerBot excels beyond industry benchmarks, outperforming every key performance indicator of our competitors. Designed with a fusion of next-generation technology and intuitive strategies, it not only meets but consistently exceeds industry expectations. The TowerBot framework harnesses exclusive methodologies and advanced tools, resulting in optimized solutions that blend superior automation technology with intelligent software, ensuring rapid, precise, and profitable order fulfillment.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="features">
                <div className="container">
                    <div className="row">
                        <h4>
                            <span>Performance Metrics:</span> At a Glance
                        </h4>
                    </div>
                </div>
                <div className="container max-width-adaptive-md margin-bottom-lg">
                    <ul className="stack-cards js-stack-cards" style={{ paddingBottom: '36px' }}>
                        <li
                            data-theme="default"
                            className="speed stack-cards__item bg radius-lg shadow-md js-stack-cards__item"
                            style={{ transform: 'translateY(0px)' }}
                        >
                            <div className="grid">
                                <div className="col-lg-5 flex items-center height-100%">
                                    <div className="text-component padding-md">
                                        <h2>Speed</h2>
                                        <p className="display@xs">
                                            In the Urbx System, totes move with remarkable efficiency. This swift motion
                                            epitomizes the system's dedication to optimized order fulfillment,
                                            showcasing the precision and rapidity with which orders are processed.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            data-theme="default"
                            className="height white-theme stack-cards__item bg radius-lg shadow-md js-stack-cards__item"
                            style={{ transform: 'translateY(18px)' }}
                        >
                            <div className="grid">
                                <div className="col-lg-5 flex items-center height-100%">
                                    <div className="text-component padding-md">
                                        <h2>Height</h2>
                                        <p className="display@xs">
                                            Totes in our cutting-edge system can reach heights of up to 125 feet,
                                            showcasing the system's mastery in leveraging vertical space for optimal
                                            order fulfillment. This design delivers a streamlined footprint while
                                            maintaining impressive storage capacity.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            data-theme="default"
                            className="payload white-theme stack-cards__item bg radius-lg shadow-md js-stack-cards__item"
                            style={{ transform: 'translateY(36px)' }}
                        >
                            <div className="grid">
                                <div className="col-lg-5 flex items-center height-100%">
                                    <div className="text-component padding-md">
                                        <h2>Payload</h2>
                                        <p className="display@xs">
                                            Our totes are crafted with the intent to support weights up to 100 pounds,
                                            highlighting the system's potential to handle both volume and heft with
                                            ease. With such capabilities in mind, we aim to provide unparalleled
                                            efficiency for even the most demanding orders.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            data-theme="default"
                            className="simple white-theme stack-cards__item bg radius-lg shadow-md js-stack-cards__item"
                            style={{ transform: 'translateY(36px)' }}
                        >
                            <div className="grid">
                                <div className="col-lg-5 flex items-center height-100%">
                                    <div className="text-component padding-md">
                                        <h2>Simple Integration</h2>
                                        <p className="display@xs">
                                            The Urbx System seamlessly bridges storage to peripherals through its intuitive conveyors, and is designed to integrate effortlessly with existing conveyor systems. This streamlined integration simplifies the entire logistical process, ensuring that items flow smoothly from storage areas to their respective destinations. Such a design not only boosts operational efficiency but also diminishes the complexities typically associated with system integrations.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            data-theme="default"
                            className="density white-theme stack-cards__item bg radius-lg shadow-md js-stack-cards__item"
                            style={{ transform: 'translateY(36px)' }}
                        >
                            <div className="grid">
                                <div className="col-lg-5 flex items-center height-100%">
                                    <div className="text-component padding-md">
                                        <h2>Highest Density</h2>
                                        <p className="display@xs">
                                            The Urbx System redefines storage efficiency with its double-deep retrieval
                                            capability in three directions. This innovative approach ensures the highest
                                            density storage possible, maximizing space while guaranteeing quick and easy
                                            access to items. By optimizing storage in this unique manner, Urbx offers
                                            unparalleled density without compromising retrieval speed or precision.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            data-theme="default"
                            className="consol white-theme stack-cards__item bg radius-lg shadow-md js-stack-cards__item"
                            style={{ transform: 'translateY(36px)' }}
                        >
                            <div className="grid">
                                <div className="col-lg-5 flex items-center height-100%">
                                    <div className="text-component padding-md">
                                        <h2>Buffering</h2>
                                        <p className="display@xs">
                                            The Urbx System redefines order buffering with enhanced efficiency. Built
                                            with sophisticated mechanisms, it's tailored to quickly consolidate complex
                                            orders. This swift assembly capability is geared towards minimizing the lag
                                            between order placement and shipment, prioritizing prompt delivery for our
                                            customers.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            data-theme="default"
                            className="pickstation white-theme stack-cards__item bg radius-lg shadow-md js-stack-cards__item"
                            style={{ transform: 'translateY(36px)' }}
                        >
                            <div className="grid">
                                <div className="col-lg-5 flex items-center height-100%">
                                    <div className="text-component padding-md">
                                        <h2>Pickstation</h2>
                                        <p className="display@xs">
                                            The Urbx Pickstation is where innovation meets efficiency. Designed with
                                            precision and user experience in mind, our pickstation streamlines the order
                                            fulfillment process, ensuring that items are selected quickly and
                                            accurately. Every detail, from its ergonomic design to its intuitive
                                            interface, is crafted to enhance productivity, reduce errors, and facilitate
                                            a smoother workflow.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>

            <section className="insight">
                <div className="container">
                    <div className="row">
                        <div className="title">
                            <h3>
                                Insight for fulfillment <br />
                                <span>innovation</span>
                            </h3>
                        </div>
                    </div>
                    <div className="blog-list">
                        <div className="row sm-tablet-gap">
                            <div className="col-10 col-sm-8 mx-auto col-md-4">
                                <div className="inner">
                                    <div
                                        className="thumbnail"
                                        style={{ background: 'url(assets/img/blog1.png)' }}
                                    ></div>
                                    <div className="flex-grow-1 pb-4 d-flex flex-column justify-space-between">
                                        <div className="flex-grow-1">
                                            <p className="date">Blog . 5 min</p>
                                            <h4>Amicable Resolution Between Urbx and Attabotics</h4>
                                            <p>
                                                Urbx is pleased to announce that Attabotics and Urbx have amicably
                                                resolved.
                                            </p>
                                        </div>
                                        <Link to="/attabotics-news">Read More</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-10 col-sm-8 mx-auto col-md-4">
                                <div className="inner">
                                    <div
                                        className="thumbnail"
                                        style={{ background: 'url(assets/img/blog2.png)' }}
                                    ></div>
                                    <div className="flex-grow-1 pb-4 d-flex flex-column justify-space-between">
                                        <div className="flex-grow-1">
                                            <p className="date">Blog . 5 min</p>
                                            <h4>Cutting Costs and Carbon: The Benefits of MFCs for E-Com</h4>
                                            <p>
                                                The URBX Double Deep TowerBot is a cutting-edge solution for warehouses
                                                and distribution...
                                            </p>
                                        </div>
                                        <Link to="/carbon-news">Read More</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-10 col-sm-8 mx-auto col-md-4">
                                <div className="inner">
                                    <div
                                        className="thumbnail"
                                        style={{ background: 'url(assets/img/blog3.png)' }}
                                    ></div>
                                    <div className="flex-grow-1 pb-4 d-flex flex-column justify-content-between">
                                        <div className="flex-grow-1">
                                            <p className="date">Blog . 5 min</p>
                                            <h4>Transforming the Grocery Industry with Drive Thru</h4>
                                            <p>The grocery industry is on the brink of a major transformation...</p>
                                        </div>
                                        <Link to="/news">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="cta graybg">
                <div className="container">
                    <div className="bluebg">
                        <h3>
                            Get ready to bring <span>Urbx</span>
                            <br />
                            to your Warehouse
                        </h3>
                        <div className="btns">
                            <Link to="/contact-us" className="transparent">
                                Contact Us
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
