import React from 'react';
import { Modal } from 'react-bootstrap';

interface VideoModalProps {
    show: boolean;
    onHide: () => void;
}

const VideoModal: React.FC<VideoModalProps> = ({ show, onHide }) => {
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="xl" // Set the size to extra-large (or adjust as needed)
            fullscreen // Enable fullscreen mode
            dialogClassName="modal-100w" // Apply a custom class to make it 100% wide
            centered
        >
            <Modal.Header closeButton />
            <Modal.Body style={{ height: '100vh' }}>
                <iframe
                    title="YouTube Video"
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/_PeYvImR03E"
                    allowFullScreen
                ></iframe>
            </Modal.Body>
        </Modal>
    );
};

export default VideoModal;
