import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function AboutUs() {
useEffect(() => {
    return () => {
      document.title = 'Urbx - About Us';
    };
  }, []);
  return (
    <>
      <section className="hero hero-inner-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <p className="intro">About Us</p>
              <h2>The global on-demand, fulfillment <span>platform.</span></h2>
            </div>
          </div>
        </div>
      </section>

      <section className="feagtured-image">
        <div className="container">
          <div className="bg">
            <div className="d-flex align-items-center justify-content-center">
              <img src="assets/img/bf.png" alt="" className="img-fluid" style={{ borderRadius: '20px' }} />
            </div>
          </div>
        </div>
      </section>

      <section className="board">
        <div className="container">
          <div className="row">
            <div className="title">
              <h2>Urbx <span>Leadership</span></h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <h4>Andrew Breckenridge</h4>
              <p className="position">Board Member</p>
              <p>
                Andrew has over 30 years of executive global supply chain experience, helping industry leaders and organizations transform their businesses through supply chain capability and enablement to achieve challenging business goals. He is currently an Executive VP and Strategic Adviser to FORTNA, and has previously served in various leadership positions with Aero Fulfillment Services, Melbourne Markets (Australia), and spent several years consulting in the supply chain arena with Price Waterhouse.
              </p>
            </div>
            <div className="col-lg-3">
              <h4>Lincoln Cavalieri</h4>
              <p className="position">Board Member & CEO</p>
              <p>
                Lincoln possesses extensive experience in software design and product development. Before his time at Urbx, he founded Omega Group, spearheading the development of products and software solutions for notable entities like BlackRock, Stryker, and several other Fortune 500 companies.
              </p>
            </div>
            <div className="col-lg-3">
              <h4>Lincoln Sise</h4>
              <p className="position">Board Member-Elect & COO</p>
              <p>
                Lincoln is a seasoned operations professional with a diverse background in leadership roles. He spent seven years at Symbotic, where he held various positions including VP of Implementation, Supply Chain, Manufacturing, and Project Management. During his tenure, Lincoln played a pivotal role in the significant scaling of deployments and product development initiatives. Prior to his time at Symbotic, Lincoln spent 14 years at Raytheon [RTX], where he managed large-scale operations, overseeing factories with over 1000 employees in Florida and Texas.
              </p>
            </div>
            <div className="col-lg-3">
              <h4>Jake Gearwar</h4>
              <p className="position">CFO</p>
              <p>
                Jake has over 20 years of financial, operational, and business experience at both public and private companies. Prior to Urbx, Jake spent over 12 years at Symbotic, most recently as VP of Financial Planning & Analysis and helped grow the company from a private start up to a billion-dollar public company. While at Symbotic, Jake held multiple finance roles, including VP Finance & Controller. In these roles, he was responsible for all aspects of FP&A, accounting, tax, and treasury. Prior to Symbotic, Jake managed FP&A for Netezza Corporation, which was acquired by IBM in 2010. Jake also held various financial roles at New Balance and the May Department Stores Company. He has a bachelor’s degree in Economics-Finance from Bentley University.
              </p>
            </div>
            <div className="col-lg-3">
              <h4>Joseph Fleming</h4>
              <p className="position">Board Member</p>
              <p> 
                Joseph is a lawyer with more than 35 years of corporate and securities legal experience. He has served as legal counsel to private and public companies and funds advising clients on, for example, complex corporate transactional matters, fund formation, board governance, and regulatory compliance matters.
              </p>
            </div>
            <div className="col-lg-3">
              <h4>Rudi Lueg</h4>
              <p className="position">CRO</p>
              <p>
                Rudi was Managing Director of Exotec North America, an international supplier of robotic systems for the supply chain. He joins the team with three decades of experience in the supply chain industry, including leadership positions at KNAPP, Fortna, and SDI among others.
              </p>
            </div>
            <div className="col-lg-3">
              <h4>Nikko Pianetto</h4>
              <p className="position">Board Member</p>
              <p>
                Nikko has extensive experience in supply chain and organizational leadership.  Before joining URBX he was the Executive Vice President of Professional Services at Fortna and also served as the Vice President of Software Engineering for Daifuku/Wynright.  Nikko is currently a partner at an investment firm focusing on angel level startup investing.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="about-product">
        <div className="container">
          <div className="bg">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-lg-8">
                <p className="intro">Urbx System</p>
                <h3>Scaling Robotics to drive <span>eCommerce</span></h3>
                <h4>Maximizing Efficiency and Accuracy</h4>
                <p>
                  Discover the game-changing potential of Urbx's cutting-edge
                  fulfillment system, engineered with a double deep storage
                  configuration and an efficient conveyor system. Our innovative
                  solution maximizes space utilization while streamlining the
                  picking of totes or containers, resulting in unprecedented
                  levels of efficiency and accuracy.
                </p>
              </div>
              <div
                className="col-lg-4 d-flex align-items-center justify-content-center"
              >
                <img src="assets/img/faq.png" alt="" className="img-fluid" style={{ borderRadius: '20px' }} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="cta graybg p100">
        <div className="container">
          <div className="row">
            <div className="bluebg">
              <h3>Get ready to bring Urbx to your Warehouse</h3>
              <div className="btns">
                <Link to="/contact-us" className="transparent">Contact Us</Link>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}
