import { Link } from 'react-router-dom'
export default function AppHeader() {
    return (
        <header>
            <nav className="navbar navbar-expand-lg shadow-5-strong">
                <div className="container">
                    <Link className="nav-link" to="https://new.urbx.com/"
                    ><img src="assets/img/logo-dark.svg" alt="" className="img-fluid" />
                    </Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link style={{fontSize:"16px"}} className="nav-link" to="/enterprise">Enterprise</Link>
                            </li>
                        </ul>
                        <ul className="navbar-nav flex-row">
                            <li><Link className="nav-link" to="/news">News</Link></li>
                            <li><Link className="nav-link" to="/about-us">About Us</Link></li>
                            <li className="special"><Link className="nav-link" to="/contact-us">Contact</Link></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    )
}
