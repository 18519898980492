import React from 'react';
import { Link } from 'react-router-dom';

export default function AttaboticsNews() {
    return (
        <>
            <section className="hero hero-inner-page news-hero">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <h2>
                                Insight for <br />
                                fulfillment <span>innovation.</span>
                            </h2>
                        </div>

                        <div className="col-lg-6 desktop-news">
                            <div className="read-more-blogs">
                                <div className="row d-flex justify-content-center align-items-center">
                                    <div className="col-lg-11 col-9">
                                        <h5>More News</h5>
                                    </div>
                                    <div className="col-lg-1 col-3">
                                        <img src="assets/img/arrow.svg" alt="" className="" />
                                    </div>
                                </div>
                            </div>
                            <section className="insight">
                                <div className="blog-list">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="inner">
                                                <div
                                                    className="thumbnail"
                                                    style={{ background: 'url(assets/img/blog1.png)' }}
                                                ></div>
                                                <div className="flex-grow-1 pb-4 d-flex flex-column justify-space-between">
                                                    <div className="flex-grow-1">
                                                        <h4>Amicable Resolution Between Urbx and Attabotics</h4>
                                                    </div>

                                                    <Link to="/attabotics-news">Read More</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="inner">
                                                <div
                                                    className="thumbnail"
                                                    style={{ background: 'url(assets/img/blog2.png)' }}
                                                ></div>
                                                <div className="flex-grow-1 pb-4 d-flex flex-column justify-space-between">
                                                    <div className="flex-grow-1">
                                                        <h4>
                                                            Cutting Costs and Carbon: The Benefits of MFCs for E-Com
                                                        </h4>
                                                    </div>

                                                    <Link to="/carbon-news">Read More</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>

            <div className="news-hero container hero mobile-news">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="read-more-blogs">
                            <div className="row d-flex justify-content-center align-items-center">
                                <div className="col-lg-11 col-9">
                                    <h5>More News</h5>
                                </div>
                                <div className="col-lg-1 col-3">
                                    <img src="assets/img/arrow.svg" alt="" className="" />
                                </div>
                            </div>
                        </div>
                        <section className="insight">
                            <div className="container">
                                <div className="row">
                                    <div className="blog-list">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="inner">
                                                    <div
                                                        className="thumbnail"
                                                        style={{ background: 'url(assets/img/blog1.png)' }}
                                                    ></div>

                                                    <h4>4 massive barries to scale solved by local fulfillment</h4>

                                                    <button>Read More</button>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="inner">
                                                    <div
                                                        className="thumbnail"
                                                        style={{ background: 'url(assets/img/blog2.png)' }}
                                                    ></div>
                                                    <h4>4 massive barries to scale solved by local fulfillment</h4>

                                                    <button>Read More</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>

            <section className="news-single">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <p className="intro">Urbx vs Attabotics</p>
                        </div>
                        <div className="col-lg-9">
                            <h3>Settlement of Litigation Between Parties</h3>
                            <h5>
                                Urbx is pleased to announce that Attabotics and Urbx have amicably resolved the
                                litigation between them. As noted in the parties’ joint motion to dismiss dated June 12,
                                2023, which was granted by the district court, the parties have reached a mutual
                                resolution.
                            </h5>
                            <h5>
                                Out of respect for the confidentiality of the process and in compliance with the
                                agreement reached, there will be no further public statements or disclosures regarding
                                the specific terms of the agreement.
                            </h5>
                            <h5>
                                Both Attabotics and Urbx are looking forward to moving ahead and focusing on their
                                respective businesses.
                            </h5>
                            <h5>
                                Urbx thanks all stakeholders for their support and understanding during this period.
                            </h5>
                            <br />
                            <h6>
                                Please note: This is the only information that will be made available regarding the
                                settlement. The parties have agreed to maintain confidentiality about the specific terms
                                of the agreement and related matters.
                            </h6>
                        </div>
                    </div>
                </div>
            </section>

            <section className="insight">
                <div className="container">
                    <div className="row">
                        <div className="title">
                            <h3>
                                Insight for fulfillment <br />
                                <span>innovation</span>
                            </h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="blog-list">
                            <div className="row sm-tablet-gap">
                                <div className="col-10 col-sm-8 mx-auto col-md-4">
                                    <div className="inner">
                                        <div
                                            className="thumbnail"
                                            style={{ background: 'url(assets/img/blog1.png)' }}
                                        ></div>
                                        <div className="flex-grow-1 pb-4 d-flex flex-column justify-space-between">
                                            <div className="flex-grow-1">
                                                <p className="date">Blog . 5 min</p>
                                                <h4>Amicable Resolution Between Urbx and Attabotics</h4>
                                                <p>
                                                    Urbx is pleased to announce that Attabotics and Urbx have amicably
                                                    resolved.
                                                </p>
                                            </div>
                                            <Link to="/attabotics-news">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-10 col-sm-8 mx-auto col-md-4">
                                    <div className="inner">
                                        <div
                                            className="thumbnail"
                                            style={{ background: 'url(assets/img/blog2.png)' }}
                                        ></div>
                                        <div className="flex-grow-1 pb-4 d-flex flex-column justify-space-between">
                                            <div className="flex-grow-1">
                                                <p className="date">Blog . 5 min</p>
                                                <h4>Cutting Costs and Carbon: The Benefits of MFCs for E-Com</h4>
                                                <p>
                                                    The URBX Double Deep TowerBot is a cutting-edge solution for
                                                    warehouses and distribution...
                                                </p>
                                            </div>
                                            <Link to="/carbon-news">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-10 col-sm-8 mx-auto col-md-4">
                                    <div className="inner">
                                        <div
                                            className="thumbnail"
                                            style={{ background: 'url(assets/img/blog3.png)' }}
                                        ></div>
                                        <div className="flex-grow-1 pb-4 d-flex flex-column justify-space-between">
                                            <div className="flex-grow-1">
                                                <p className="date">Blog . 5 min</p>
                                                <h4>Transforming the Grocery Industry with Drive Thru</h4>
                                                <p>The grocery industry is on the brink of a major transformation...</p>
                                            </div>
                                            <Link to="/news">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="cta graybg c-padding">
                <div className="container">
                    <div className="row">
                        <div className="bluebg">
                            <h3>
                                Get ready bring <span>Urbx</span>
                                <br />
                                to your Warehouse
                            </h3>
                            <div className="btns">
                                <Link to="/contact-us" className="transparent">
                                    Contact Us
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
