import { Route, Routes } from 'react-router-dom';
import Home from '../Components/HomePage/Home';
import NotFoundError from '../Components/Common/NotFoundError';
import Enterprise from '../Components/Enterprise/Enterprise';
import EdgeServices from '../Components/EdgeServices/EdgeServices';
import News from '../Components/News/News';
import AboutUs from '../Components/AboutUs/AboutUs';
import ContactUs from '../Components/ContactUs/ContactUs';
import AttaboticsNews from '../Components/News/AttaboticsNews';
import CarbonNews from '../Components/News/CarbonNews';

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

export default function AppRouter() {
    return (
        <>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/enterprise" element={<Enterprise />} />
                <Route path="/edge-services" element={<EdgeServices />} />
                <Route path="/news" element={<News />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/attabotics-news" element={<AttaboticsNews />} />
                <Route path="/carbon-news" element={<CarbonNews />} />
                <Route path="*" element={<NotFoundError message={'Page not found'} />} />
            </Routes>
        </>
    );
}
