import React, { useState } from 'react'
import { showToast } from '../../APIFunctions/Helpers/toastHelpers';
import { subscribe } from '../../APIFunctions/App/Subscribe';
import { isResponseError } from '../../APIFunctions/Helpers/commonHelpers';
import CircularLoader from '../Common/CircularLoader';

export interface emailSubscribe {
    email: string;
}

const initial_email: emailSubscribe = {
    email: ''
}




export default function Subscribe() {
    const [new_subscription, setNewSubscription] = useState<emailSubscribe>(initial_email);
const [loading, setLoading] = useState<boolean>(false);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setNewSubscription({ ...new_subscription, [name]: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        saveEmail(new_subscription);
    };

const isValidSubscribe = (obj: emailSubscribe) => {
        const requiredFields = ['email'];
        return requiredFields.every(field => obj[field].length > 0);
    }

    const saveEmail = async (e) => {
        if (isValidSubscribe(new_subscription)) {
            setLoading(true)
        var newEmail = await subscribe(new_subscription);
        if (!isResponseError(newEmail)) {
            showToast("success", "Thank you for subscribing to our newsletter")
            setNewSubscription(initial_email)
        }
setLoading(false)
        }
    }

    return (
        <form className="newsletter-form">
            <input
                type="email"
                className="mail"
                placeholder="Your email"
                name="email" value={new_subscription.email} onChange={handleInputChange}
            />
            {loading
                ? <CircularLoader color={"#00a5e2"} size={40} />
                : <button
                    disabled={loading || !isValidSubscribe(new_subscription)}
                    className={`${loading || !isValidSubscribe(new_subscription) ? 'disabled' : 'subscribe'}`}
                    onClick={handleSubmit}
                >
                    Subscribe
                </button>}
        </form>
    )
}
