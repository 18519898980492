import React from 'react';
import { Link } from 'react-router-dom';

export default function News() {
    return (
        <>
            <section className="hero hero-inner-page news-hero">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <h2 style={{ fontSize: '48px' }}>
                                Insight for <br />
                                fulfillment <span>innovation.</span>
                            </h2>
                        </div>

                        <div className="col-lg-6 desktop-news">
                            <div className="read-more-blogs">
                                <div className="row d-flex justify-content-center align-items-center">
                                    <div className="col-lg-11 col-9">
                                        <h5 className="mx-0">More News</h5>
                                    </div>
                                    <div className="col-lg-1 col-3">
                                        <img src="assets/img/arrpw.svg" alt="" className="" />
                                    </div>
                                </div>
                            </div>
                            <section className="insight">
                                <div className="blog-list">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="inner">
                                                <div
                                                    className="thumbnail"
                                                    style={{ background: 'url(assets/img/blog1.png)' }}
                                                ></div>
                                                <div className="flex-grow-1 pb-4 d-flex flex-column justify-space-between">
                                                    <div className="flex-grow-1">
                                                        <h4>Amicable Resolution Between Urbx and Attabotics</h4>
                                                    </div>

                                                    <Link to="/attabotics-news">Read More</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="inner">
                                                <div
                                                    className="thumbnail"
                                                    style={{ background: 'url(assets/img/blog2.png)' }}
                                                ></div>
                                                <div className="flex-grow-1 pb-4 d-flex flex-column justify-space-between">
                                                    <div className="flex-grow-1">
                                                        <h4>
                                                            Cutting Costs and Carbon: The Benefits of MFCs for E-Com
                                                        </h4>
                                                    </div>

                                                    <Link to="/carbon-news">Read More</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>

            <section className="only-image"></section>

            <section className="news-single">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <p className="intro" style={{ fontSize: '18px' }}>
                                Micro Fulfillment
                            </p>
                        </div>
                        <div className="col-lg-9">
                            <h3>
                                Transforming the Grocery Industry with Drive Thru E-commerce Pickup and Micro
                                Fulfillment Centers
                            </h3>
                            <h5>
                                The grocery industry is on the brink of a major transformation, and our company, Urbx,
                                is at the forefront of this change.
                            </h5>
                            As pioneers in the field of micro fulfillment solutions, we are leading the charge in the
                            rapidly evolving world of robotics automation in the grocery industry. Our groundbreaking
                            technology has the potential to revolutionize the way groceries are distributed and
                            purchased, making the process faster, more efficient, and more convenient for consumers than
                            ever before.
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <img src="assets/img/news-1.png" alt="" className="img-fluid" />
                        </div>
                        <div className="col-lg-9">
                            <img src="assets/img/news-2.png" alt="" className="img-fluid" />
                            <p>
                                One of the key innovations driving this change is the use of drive thru e-commerce
                                pickup points. This will allow customers to quickly and easily pick up their groceries
                                without having to leave their cars, making the grocery shopping experience even more
                                convenient. By leveraging this technology, we will be able to provide a level of
                                convenience and accessibility that was previously unimaginable in the grocery industry.
                            </p>
                            <h5 className="top-50">Pickup Points</h5>
                            <p>
                                In addition to drive thru pickup points, our pioneering micro fulfillment centers will
                                also play a critical role in the evolution of the grocery industry. By bringing the
                                store directly to the consumer through small, automated warehouses placed in urban
                                areas, we will be able to offer lightning-fast delivery of groceries.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="news-single">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <p className="intro" style={{ fontSize: '18px' }}>
                                Micro Fulfillment
                            </p>
                        </div>
                        <div className="col-lg-9">
                            <h3>Convenience, convenience, convenience</h3>
                            <h5>It's not just about the speed</h5>
                            But speed isn't the only advantage of our micro fulfillment centers. Our technology is also
                            incredibly efficient, allowing us to store vast amounts of items in a small footprint. This
                            means that we will be able to serve even the most densely populated urban areas with ease,
                            providing unparalleled convenience for consumers.
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <img src="assets/img/news-3.gif" alt="" className="img-fluid" />
                        </div>
                        <div className="col-lg-9">
                            <img src="assets/img/news-4.png" alt="" className="img-fluid" />
                            <p>
                                In addition to making grocery delivery more convenient and efficient, our micro
                                fulfillment centers and robotics automation will also drive sustainability in the
                                grocery industry. By automating many of the tasks involved in picking, packing, and
                                distributing groceries, we will be able to reduce labor costs and increase the speed at
                                which orders are fulfilled. This will lead to lower prices for consumers, as well as a
                                more sustainable supply chain.
                            </p>
                            <h5 className="top-50">In conclusion</h5>
                            <p>
                                We believe that the future of the grocery industry is full of possibilities. With our
                                cutting-edge drive thru e-commerce pickup points and micro fulfillment centers, we are
                                paving the way for a new era of convenience and sustainability in the grocery industry.
                                We are excited to bring this technology to market and can't wait to see where it takes
                                us.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="insight">
                <div className="container">
                    <div className="row">
                        <div className="title">
                            <h3>
                                Insight for fulfillment <br />
                                <span>innovation</span>
                            </h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="blog-list">
                            <div className="row sm-tablet-gap">
                                <div className="col-10 col-sm-8 mx-auto col-md-4">
                                    <div className="inner">
                                        <div
                                            className="thumbnail"
                                            style={{ background: 'url(assets/img/blog1.png)' }}
                                        ></div>
                                        <div className="flex-grow-1 pb-4 d-flex flex-column justify-space-between">
                                            <div className="flex-grow-1">
                                                <p className="date">Blog . 5 min</p>
                                                <h4>Amicable Resolution Between Urbx and Attabotics</h4>
                                                <p>
                                                    Urbx is pleased to announce that Attabotics and Urbx have amicably
                                                    resolved.
                                                </p>
                                            </div>
                                            <Link to="/attabotics-news">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-10 col-sm-8 mx-auto col-md-4">
                                    <div className="inner">
                                        <div
                                            className="thumbnail"
                                            style={{ background: 'url(assets/img/blog2.png)' }}
                                        ></div>
                                        <div className="flex-grow-1 pb-4 d-flex flex-column justify-space-between">
                                            <div className="flex-grow-1">
                                                <p className="date">Blog . 5 min</p>
                                                <h4>Cutting Costs and Carbon: The Benefits of MFCs for E-Com</h4>
                                                <p>
                                                    The URBX Double Deep TowerBot is a cutting-edge solution for
                                                    warehouses and distribution...
                                                </p>
                                            </div>
                                            <Link to="/carbon-news">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-10 col-sm-8 mx-auto col-md-4">
                                    <div className="inner">
                                        <div
                                            className="thumbnail"
                                            style={{ background: 'url(assets/img/blog3.png)' }}
                                        ></div>
                                        <div className="flex-grow-1 pb-4 d-flex flex-column justify-space-between">
                                            <div className="flex-grow-1">
                                                <p className="date">Blog . 5 min</p>
                                                <h4>Transforming the Grocery Industry with Drive Thru</h4>
                                                <p>The grocery industry is on the brink of a major transformation...</p>
                                            </div>
                                            <Link to="/news">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="cta graybg c-padding">
                <div className="container">
                    <div className="row">
                        <div className="bluebg">
                            <h3>
                                Get ready bring <span>Urbx</span>
                                <br />
                                to your Warehouse
                            </h3>
                            <div className="btns">
                                <Link to="/contact-us" className="transparent">
                                    Contact Us
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
