import Error from '../Static/images/Error.png';

interface NotFoundErrorProps {
  message: string;
}
const NotFoundError: React.FC<NotFoundErrorProps> = ({ message }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '90vh',
        marginLeft: '240px',
      }}
    >
      <img src={Error} alt="No Order" style={{ width: '100px', marginBottom: '30px' }} />
      <h2>{message}</h2>
    </div>
  );
};

export default NotFoundError;
