import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from './Modal';

export default function Home() {
    useEffect(() => {
        window.location.href = 'https://new.urbx.com/';
    }, []);
    
    const [showModal, setShowModal] = useState(false);
    const videoRef1 = useRef<HTMLVideoElement>(null);
    const videoRef2 = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (videoRef1.current) {
            videoRef1.current.play();
        }

        if (videoRef2.current) {
            videoRef2.current.play();
        }
    }, []);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };
    return (
        <>
            <section className="hero ship-man">
                <div className="video-background hidden-on-mobile">
                    <video ref={videoRef1} playsInline autoPlay muted loop>
                        <source src="assets/img/botmain2.mp4" type="video/mp4" />
                    </video>
                </div>
                <div className="video-background visible-only-mobile">
                    <video
                        ref={videoRef2}
                        playsInline
                        autoPlay
                        muted
                        loop
                        style={{
                            objectFit: 'cover',
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <source src="assets/img/botmain2_mobile.mp4" type="video/mp4" />
                    </video>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <p className="intro" style={{ color: 'white' }}>
                                Introducing Urbx
                            </p>
                            <h2 className="mainb" style={{ fontSize: '48px' }}>
                                The vertical <br /> revolution™
                            </h2>
                        </div>
                    </div>
                </div>
            </section>

            <section className="system">
                <div className="container features">
                    <div className="row d-flex header-sys">
                        <div className="col-lg-4" style={{ marginBottom: '20px' }}>
                            <div className="system-img-wrap">
                                <img src="assets/img/us.png" alt="" className="img-fluid object-cover" />
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <p className="intro">Introducing the Urbx System</p>
                            <h3>
                                Scaling robotics to <br />
                                speed up <span>e-commerce</span>
                            </h3>
                            <h4>How it all works</h4>
                            <p>
                                Elevate order fulfillment with Urbx's robotic platform, featuring double deep storage and conveyor technology. Designed with scalability in mind, our TowerBots aim to utilize vertical spaces effectively, optimizing storage solutions even in confined areas. This fusion of robotics and software represents our vision for a high-density automation solution, setting sights on unparalleled efficiency and speed across diverse environments.
                            </p>
                            <button className="link-more fullvideo" onClick={openModal}>
                                Watch full video ▶
                            </button>
                            <Modal show={showModal} onHide={closeModal} />
                        </div>
                    </div>
                    <div className="row">
                        <a className="col-lg-6" href="/enterprise" style={{ textDecoration: 'none', color: 'white' }}>
                            <div className="system-item">
                                <div
                                    className="featured-image"
                                    style={{
                                        background: 'url(assets/img/enterprise-index.png)',
                                        backgroundSize: 'cover',
                                    }}
                                ></div>
                                <div className="content">
                                    <h3>
                                        <span>Enterprise</span>
                                    </h3>
                                    <div className="link">
                                        <img src="assets/img/blue-arrow.svg" alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a className="col-lg-6" href="/about-us" style={{ textDecoration: 'None', color: 'white' }}>
                            <div className="system-item">
                                <div className="featured-image" style={{ position: 'relative' }}>
                                    <video
                                        autoPlay
                                        muted
                                        loop
                                        playsInline
                                        style={{
                                            position: 'absolute',
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                            backgroundPosition: 'center',
                                            borderTopLeftRadius: '20px',
                                            borderTopRightRadius: '20px',
                                        }}
                                    >
                                        <source src="assets/img/coolclip.mp4" type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                                <div className="content">
                                    <h3>
                                        <span>About Us</span>
                                    </h3>
                                    <div className="link">
                                        <img src="assets/img/blue-arrow.svg" alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="row" style={{ scrollbarGutter: '.5rem' }}>
                        <div className="col-lg-12">
                            <div className="featured-system-item">
                                <div className="board-image">
                                    <video
                                        loop
                                        autoPlay
                                        muted
                                        style={{
                                            width: '100%',
                                            borderRadius: '20px',
                                            marginBottom: '40px',
                                        }}
                                        playsInline
                                    >
                                        <source src="assets/img/drop.mp4" type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                                <p className="intro">The Urbx System</p>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h4>
                                            Intelligent robotics powers <span>omni-channel</span> fulfillment
                                        </h4>
                                    </div>
                                    <div className="col-lg-6">
                                        <p>
                                            Urbx offers a fully automated system, incorporating multiple TowerBots and a conveyor to transport products to specific pick stations for order consolidation. This automation minimizes human errors, enhancing overall efficiency and quality. With the industry's fastest item retrieval times, the Urbx system is unmatched in performance.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="features">
                <div className="container">
                    <div className="row">
                        <h3>
                            Urbx <span>features</span>
                        </h3>
                    </div>
                </div>
                <div className="container max-width-adaptive-md margin-bottom-lg">
                    <ul className="stack-cards js-stack-cards" style={{ paddingBottom: '36px' }}>
                        <li
                            data-theme="default"
                            className="rev stack-cards__item bg radius-lg shadow-md js-stack-cards__item"
                            style={{ transform: 'translateY(0px)' }}
                        >
                            <div className="grid">
                                <div className="col-lg-5 flex items-center height-100%">
                                    <div className="text-component padding-md">
                                        <h2>Revolutionizing Storage</h2>
                                        <p className="display@xs">
                                            TowerBots navigate the grid's upper layer with efficiency, optimizing the utilization of each column. Their built-in features ensure no downtime for charging and allow for autonomous swapping during predictive maintenance. This guarantees consistent uptime and underscores our commitment to system reliability.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            data-theme="default"
                            className="unp white-theme stack-cards__item bg radius-lg shadow-md js-stack-cards__item"
                            style={{ transform: 'translateY(0px)' }}
                        >
                            <div className="grid">
                                <div className="col-lg-5 flex items-center height-100%">
                                    <div className="text-component padding-md">
                                        <h2>Unparalleled Efficiency</h2>
                                        <p className="display@xs">
                                            The Urbx system’s lower grid features embedded Conveyor Tiles, a design that
                                            unites the advantages, cost-effectiveness, and efficiency of a conveyor
                                            system. This innovative solution enables seamless integration, facilitating
                                            a truly streamlined and high-performance operation.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            data-theme="default"
                            className="acc white-theme stack-cards__item bg radius-lg shadow-md js-stack-cards__item"
                            style={{ transform: 'translateY(0px)' }}
                        >
                            <div className="grid">
                                <div className="col-lg-5 flex items-center height-100%">
                                    <div className="text-component padding-md">
                                        <h2>Rapid Fulfillment</h2>
                                        <p className="display@xs">
                                            The Urbx system is designed to fulfill orders at industry-leading speeds,
                                            setting new benchmarks for efficiency and performance. Experience
                                            unparalleled order processing and keep your business ahead of the
                                            competition with the Urbx system's remarkable fulfillment capabilities.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>

            <section className="simple-cta">
                <div className="container">
                    <div className="row">
                        <p className="intro" style={{ color: '#535b75' }}>
                            Urbx Platform
                        </p>
                        <h4 style={{ fontSize: '48px' }}>
                            <span>High-Speed Fulfillment</span> Automation <br />
                            for <span>Rapid Distribution</span>
                        </h4>
                        <p>Lets talk about the future of retail</p>
                        <div className="btns">
                            <Link to="/enterprise" className="blue">
                                Get Started
                            </Link>
                            <Link to="/contact-us" className="black">
                                Contact Us
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
