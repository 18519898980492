
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppRouter from './Routers/AppRouter';
import AppFooter from './Components/Common/AppFooter';
import AppHeader from './Components/Common/AppHeader';

function App() {
  return (
    <BrowserRouter>
      <ToastContainer position="bottom-right" />
      <AppHeader/>
      <AppRouter />
      <AppFooter />
    </BrowserRouter >
  );
}

export default App;